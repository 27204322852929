
import store from '../store'
import Fuse from "fuse.js";
import icons from "./icons";
import moment from "moment";

const helpFunctions = {
    modal_functions: {
        openModal(ref) {
            this.$refs[ref].open();
        },
        closeModal(ref) {
            this.$refs[ref].close();
        },
    },
    tags: [],
    documents: [],
    reg_exp: "",
    fuse_options: {
        isCaseSensitive: false,
        includeScore: true,
        shouldSort: true,
        minMatchCharLength: 3,
    },
    models: [
        {
            value: "Assessor",
            text: store.getters.translate("assessors"),
        },
        {
            value: "Candidate",
            text: store.getters.translate("candidates"),
        },
        {
            value: "Certificate",
            text: store.getters.translate("certificates"),
        },
        {
            value: "Company",
            text: store.getters.translate("companies"),
        },
        {
            value: "Contact",
            text: store.getters.translate("contacts"),
        },
        {
            value: "Course",
            text: store.getters.translate("courses"),
        },
        {
            value: "Dossier",
            text: store.getters.translate("dossiers"),
        },
        {
            value: "Employee",
            text: store.getters.translate("employees"),
        },
        {
            value: "Exam",
            text: store.getters.translate("exams"),
        },
        {
            value: "Form",
            text: store.getters.translate("forms"),
        },
        {
            value: "Location",
            text: store.getters.translate("locations"),
        },
        {
            value: "Note",
            text: store.getters.translate("notes"),
        },
        {
            value: "Role",
            text: store.getters.translate("roles"),
        },
        {
            value: "Room",
            text: store.getters.translate("rooms"),
        },
        {
            value: "Tag",
            text: store.getters.translate("tags"),
        },
        {
            value: "Scheme",
            text: store.getters.translate("schemes"),
        },
        {
            value: "Template",
            text: store.getters.translate("templates"),
        },
        {
            value: "Translation",
            text: store.getters.translate("translations"),
        },
        {
            value: "Workflow",
            text: store.getters.translate("workflows"),
        },
        {
            value: "User",
            text: store.getters.translate("users"),
        },
    ],
    models_to_send: {
        candidate: {
            candidate_id: true,
            initials: true,
            first_name: true,
            insertion: true,
            last_name: true,
            place_of_birth: true,
            date_of_birth: true,
            emailaddress: true,
            avatar: true
        },
        exam: {
            re_certification: true,
            re_examination: true,
            name: true,
            description: true,
            examination_date: true,
            scheme_owner: true,
            code: true
        },
        location: {
            name: true,
            description: true
        },
        address: {
            reference: true,
            type: true,
            street: true,
            zipcode: true,
            number: true,
            addon: true,
            city: true,
            municipality: true,
            country: true,
            latitude: true,
            longitude: true,
            formatted_address: true,
            name: true
        },
        room: {
            name: true
        },
        session: {
            name: true,
            cesuur: true,
            examination_date: true,
            re_examination: true,
            max_candidates: true,
            status: true
        },
        result: {
            result: true,
            from: true,
            till: true,
            remarks: true,
            result_frozen: true,
            name: true,
            status: true,
        },
        supervisor: {
            casual_name: true,
        },
        person: {
            initials: true,
            first_name: true,
            insertion: true,
            name: true,
            last_name: true,
            date_of_birth: true,
            place_of_birth: true,
            casual_name: true,
        },
        user: {
            email: true,
            type: true,
            status: true,
        },
        owner: {
            jobtitle: true,
            number: true
        },
        assessor: {
            type: true,
            reference: true
        },
        scheme: {
            reference: true,
            name: true,
            description: true,
            scheme_owner: true,
            section_name: true,
            section_number: true,
            end_result: true,
            validity: true,
            validity_period: true,
            exam_name: true,
            code: true
        },
        scheme_session: {
            name: true,
            description: true,
            type: true,
            cesuur: true,
            price: true,
            duration: true,
            max_candidates: true,
            validity_duration: true
        }
    },
    models_proxies: {
        session_results: "result",
        session: "session",
        sessions: "session",
        exam: "exam",
        exams: "exam",
        location: "location",
        addresses: "address",
        owner: "owner",
        person: "person",
        user: "user",
        scheme: "scheme",
        scheme_sessions: "scheme_session",
        scheme_session: "scheme_session",
        candidate: "candidate",
        examinators: "assessor",
    },
    field_types: [
        {
            value: "text_field",
            text: store.getters.translate("text_field"),
        },
        {
            value: "text_area",
            text: store.getters.translate("text_area"),
        },
        {
            value: "boolean",
            text: store.getters.translate("boolean"),
        },
        {
            value: "select_single",
            text: store.getters.translate("select_single"),
        },
        {
            value: "select_multiple",
            text: store.getters.translate("select_multiple"),
        },
        {
            value: "date",
            text: store.getters.translate("date"),
        },
        {
            value: "read_only",
            text: store.getters.translate("read_only"),
        },
        {
            value: "link",
            text: store.getters.translate("link"),
        },
    ],
    question_types: [
        {
            value: "text_field",
            text: store.getters.translate("text_field"),
        },
        {
            value: "text_area",
            text: store.getters.translate("text_area"),
        },
        {
            value: "boolean",
            text: store.getters.translate("boolean"),
        },
        {
            value: "select_single",
            text: store.getters.translate("select_single"),
        },
        {
            value: "select_multiple",
            text: store.getters.translate("select_multiple"),
        },
        {
            value: "date",
            text: store.getters.translate("date"),
        },
    ],
    models_with_links: [
        'assessor',
        'candidate',
        'certificate',
        'company',
        'contact',
        'course',
        'dossier',
        'employee',
        'exam',
        'note',
        'location',
        'room',
        'scheme',
        'template',
        'workflow',
        'tag',
    ],
    can_restore: [
        'assessor',
        'candidate',
        'certificate',
        'company',
        'contact',
        'course',
        'dossier',
        'employee',
        'exam',
        'note',
        'location',
        'room',
        'scheme',
        'template',
        'workflow',
        'tag',
    ],
    notes_kinds: {
        "certification_agreement": store.getters.translate("certification_agreement"),
        "process_verbal_theory_exam": store.getters.translate("process_verbal_theory_exam"),
        "practical_test": store.getters.translate("practical_test"),
        "switch_message": store.getters.translate("switch_message"),
        "theory_exam_certification_test": store.getters.translate("theory_exam_certification_test"),
        "process_verbal_practical_exam": store.getters.translate("process_verbal_practical_exam"),
        "external_exam_documents": store.getters.translate("external_exam_documents"),
        "exam_request": store.getters.translate("exam_request"),
    },
    notes_codes: {
        "certification_agreement": '02',
        "process_verbal_theory_exam": '03',
        "practical_test": '04',
        "switch_message": '05',
        "theory_exam_certification_test": '06',
        "process_verbal_practical_exam": '07',
        "external_exam_documents": '12',
        "exam_request": '13',
    },
    participantsession_headers: [
        { text: store.getters.translate("#"), value: "candidate_id", align: "start", sortable: true },
        { text: store.getters.translate("name"), value: "id", align: "start", sortable: true },
        { text: store.getters.translate("result"), value: "result" },
        { text: store.getters.translate("status"), value: "status" },
        { text: store.getters.translate("from"), value: "from" },
        { text: store.getters.translate("till"), value: "till" },
        { text: store.getters.translate("exam_version"), value: "exam_version", width: "100px" },
        { text: store.getters.translate("testvision"), value: "t_assessment_id", sortable: false, align: "center", width: "100px" },
        { text: store.getters.translate("actions"), value: "actions", sortable: false, align: "right" },
    ],
    stipel_schemesession_headers: [
        { text: " ", value: " ", align: "start", sortable: true },
        { text: store.getters.translate("sort_order"), value: "sort_order", align: "start", sortable: true },
        { text: store.getters.translate("name"), value: "name", align: "start", sortable: true },
        { text: store.getters.translate("stipel_name"), value: "stipel_name", align: "start", sortable: true },
        { text: store.getters.translate("updated"), value: "updated_at" },
        { text: store.getters.translate("actions"), value: "actions", sortable: false, align: "right" },
    ],
    schemesession_headers: [
        { text: " ", value: " ", align: "start", sortable: true },
        { text: store.getters.translate("sort_order"), value: "sort_order", align: "start", sortable: true },
        { text: store.getters.translate("name"), value: "name", align: "start", sortable: true },
        { text: store.getters.translate("updated"), value: "updated_at" },
        { text: store.getters.translate("actions"), value: "actions", sortable: false, align: "right" },
    ],
    formquestion_headers: [
        { text: " ", value: " ", align: "start", sortable: true },
        { text: store.getters.translate("sort_order"), value: "sort_order", align: "start", sortable: true },
        { text: store.getters.translate("name"), value: "name", align: "start", sortable: true },
        { text: store.getters.translate("category"), value: "category_name" },
        { text: store.getters.translate("updated"), value: "updated_at" },
        { text: store.getters.translate("actions"), value: "actions", sortable: false, align: "right" },
    ],
    questioncategory_headers: [
        { text: " ", value: " ", align: "start", sortable: true },
        { text: store.getters.translate("sort_order"), value: "sort_order", align: "start", sortable: true },
        { text: store.getters.translate("name"), value: "name", align: "start", sortable: true },
        { text: store.getters.translate("updated"), value: "updated_at" },
        { text: store.getters.translate("actions"), value: "actions", sortable: false, align: "right" },
    ],
    examstatuses_headers: [
        { text: store.getters.translate("id"), value: "id", align: "start", sortable: true },
        { text: store.getters.translate("name"), value: "name", align: "start", sortable: true },
        { text: store.getters.translate("examination_date"), value: "examination_date", align: "start", sortable: true },
        { text: store.getters.translate("location_name"), value: "location_name", align: "start", sortable: true },
        { text: store.getters.translate("joint_planning"), value: "joint_planning", align: "start", sortable: true },
        { text: store.getters.translate("location_requested"), value: "location_requested", align: "start", sortable: true },
        { text: store.getters.translate("location_agreed"), value: "location_agreed", align: "start", sortable: true },
        { text: store.getters.translate("practical_planned"), value: "practical_planned", align: "start", sortable: true },
        { text: store.getters.translate("examiner_requested"), value: "examiner_requested", align: "start", sortable: true },
        { text: store.getters.translate("examiner_agrees"), value: "examiner_agrees", align: "start", sortable: true },
        { text: store.getters.translate("passed_on_to_customer"), value: "passed_on_to_customer", align: "start", sortable: true },
        { text: store.getters.translate("stipel"), value: "stipel", align: "start", sortable: true },
        { text: store.getters.translate("running_projects"), value: "running_projects", align: "start", sortable: true },
        { text: store.getters.translate("plans_completed"), value: "plans_completed", align: "start", sortable: true },
    ],
    base_table_options: {
        assessors: {
            avatar: true,
        },
        candidates: {
            avatar: true,
        },
        certificates: {
            no_create: true,
            no_import: true,
        },
        companies: {
            avatar: true,
        },
        contacts: {
            avatar: true,
        },
        courses: {
            no_import: true,
        },
        dossiers: {
            no_create: true,
            no_import: true,
        },
        employees: {
            avatar: true,
        },
        exams: {
            no_import: true,
        },
        fields: {
            no_import: true,
            no_tags: true,
            no_select: true,
        },
        forms: {
            no_import: true,
        },
        locations: {
            no_import: true,
        },
        notes: {
            no_import: true,
            copy: true,
            no_create: true
        },
        roles: {
            no_import: true,
            no_tags: true,
            no_select: true
        },
        rooms: {
            no_import: true,
        },
        schemes: {
            no_import: true,
            copy: true,
        },
        tags: {
            no_import: true,
            no_tags: true,
        },
        templates: {
            no_import: true,
            copy: true,
        },
        translations: {
            no_import: true,
            no_tags: true,
        },
        workflows: {
            no_import: true,
            no_tags: true,
        },
    },
    base_table_includable_options: {
        assessors: {
            exams: {
                create: false,
                link: false,
                detachable: false,
                no_select: true,
            },
            courses: {
                create: false,
                link: false,
                detachable: false,
                no_select: true,
            },
            rooms: {
                create: false,
                link: true,
                detachable: true,
            },
            schemes: {
                create: false,
                link: true,
                detachable: true,
            },
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
        candidates: {
            certificates: {},
            dossiers: {},
            exams: {
                create: false,
                link: true,
                detachable: true,
            },
            courses: {
                create: false,
                link: true,
                detachable: true,
            },
            companies: {
                create: false,
                link: true,
                detachable: true,
            },
            contacts: {
                create: true,
                link: true,
                detachable: true,
            },
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
        companies: {
            contacts: {
                create: true,
                link: true,
                detachable: true,
            },
            candidates: {
                create: false,
                link: true,
                detachable: true,
            },
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
        contacts: {
            companies: {
                create: false,
                link: true,
                detachable: true,
            },
            candidates: {
                create: false,
                link: true,
                detachable: true,
            },
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
        courses: {
            exams: {
                create: true,
                link: true,
                detachable: true,
            },
            candidates: {
                create: false,
                link: true,
                detachable: true,
            },
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
        dossiers: {
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
        employees: {
            roles: {
                create: false,
                link: true,
                detachable: true,
            },
            tokens: {
                create: true,
                link: false,
                detachable: false,
            },
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
        exams: {
            assessors: {
                create: false,
                link: false,
                detachable: false,
                no_select: true,
            },
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
        forms: {
            formquestions: {
                create: true,
                link: false,
                detachable: false,
                no_select: true,
            },
            questioncategories: {
                create: true,
                link: false,
                detachable: false,
                no_select: true,
            },
        },
        locations: {
            rooms: {
                create: true,
                link: true,
                detachable: true,
            },
        },
        rooms: {
            assessors: {
                create: false,
                link: true,
                detachable: true,
            },
            courses: {
                create: false,
                link: false,
                detachable: false,
                no_select: true
            },
            exams: {
                create: false,
                link: true,
                detachable: true,
            },
        },
        schemes: {
            schemesessions: {
                create: true,
                detachable: true,
                no_select: true,
            },
            assessors: {
                create: false,
                link: true,
                detachable: true,
            },
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
        templates: {
            formquestions: {
                create: true,
                link: false,
                detachable: false,
                no_select: true,
            },
            questioncategories: {
                create: true,
                link: false,
                detachable: false,
                no_select: true,
            },
        },
    },
    default_trigger: {
        workflow_id: null,
        model: "",
        type: "added_tag",
        from: "",
        to: "",
        operator: "=",
        parameters: {
            operator: "=",
            compared_value: "",
            compared_type: "",
        },
        amount_of_repeats: 1,
    },
    default_action: {
        workflow_id: null,
        type: "notify_users",
        models: [],
        message: "",
        parameters: {
            visible_candidate: false,
            editable_candidate: false,
            visible_contact: false,
            editable_contact: false,
            visible_assessor: false,
            editable_assessor: false,
            is_request: false,
            request_fulfilled: false,
            generate_document: false,
            new_note: false,
            template_id: null,
            document_name: null,
            change_status: null,
            attach_to: [],
            linked_model_attach_to: [],
            tree: null,
            send_email: false,
            tags: [],
            only_latest_record: false,
            notify_contacts: false,
            document_type: null,
            repeat: false
        },
    },
    trigger_types: [
        {
            value: "added_tag",
            text: store.getters.translate("added_tag"),
            icon: "mdi-tag-plus-outline",
        },
        {
            value: "tag_exist",
            text: store.getters.translate("tag_exist"),
            icon: "mdi-tag-plus-outline",
        },
        {
            value: "field_changes",
            text: store.getters.translate("field_changes"),
            icon: "mdi-form-textbox",
        },
        {
            value: "scheduler",
            text: store.getters.translate("scheduler"),
            icon: "mdi-calendar",
        },
        {
            value: "before_date",
            text: store.getters.translate("before_date"),
            icon: "mdi-calendar",
        },
        {
            value: "after_date",
            text: store.getters.translate("after_date"),
            icon: "mdi-calendar",
        },
    ],
    action_types: [
        {
            value: "notify_current_user",
            text: store.getters.translate("notify_current_user"),
            icon: "mdi-message-processing-outline",
        },
        {
            value: "notify_users",
            text: store.getters.translate("notify_users"),
            icon: "mdi-android-messages",
        },
        {
            value: "notify_roles",
            text: store.getters.translate("notify_roles"),
        },
        {
            value: "notify_model",
            text: store.getters.translate("notify_model"),
            icon: "mdi-account-arrow-right",
        },
        {
            value: "attach_tag",
            text: store.getters.translate("attach_tag"),
            icon: "mdi-tag-plus-outline",
        },
        {
            value: "remove_tag",
            text: store.getters.translate("remove_tag"),
            icon: "mdi-tag-remove-outline",
        },
        {
            value: "create_note",
            text: store.getters.translate("create_note"),
            icon: "mdi-note-plus",
        },
        {
            value: "change_status",
            text: store.getters.translate("change_status"),
            icon: "mdi-traffic-light",
        },
        {
            value: "create_form",
            text: store.getters.translate("create_form"),
            icon: "mdi-file-table-outline",
        },
    ],
    default_note: {
        title: "",
        content: "",
        category: "",
        files: [],
        visible_candidate: false,
        editable_candidate: false,
        visible_contact: false,
        editable_contact: false,
        visible_assessor: false,
        editable_assessor: false,
        tags: [],
        template_id: null,
        document_name: null,
        generate_document: false,
        assessor_ids: [],
        candidate_ids: [],
        exam_ids: [],
        company_ids: [],
        dossier_ids: [],
        dossiers: [],
        candidates: [],
        exams: [],
        assessors: [],
        schemes: [],
        employees: [],
        contacts: [],
        companies: [],
        custom_fields: {},
    },
    default_exam: {
        name: "",
        description: "",
        course_id: null,
        exam_company_id: null,
        examination_date: null,
        re_examination: false,
        re_certification: false,
        location_id: null,
        re_exam_id: null,
        reference: "",
        room_id: null,
        scheme_id: null,
        candidates: [],
        sessions: [],
        scheme_owner: null,
    },
    default_course: {
        name: "",
        description: "",
        start_date: null,
        end_date: null,
        time: null,
        location_id: null,
        room_id: null,
        scheme_id: null,
        assessor_id: null,
        maximum_capacity: null,
        reference: "",
    },
    default_people_model: {
        type: "",
        notify: false,
        initials: "",
        first_name: "",
        insertion: "",
        last_name: "",
        date_of_birth: "",
        phonenumber: "",
        mobilenumber: "",
        billingnumber: "",
        gender: "",
        country_of_birth_id: "",
        place_of_birth: "",
        nationality_id: "",
        financial_name: "",
        contact: {
            can_view_certificates: true,
            newsletter: false,
            custom_fields: {}
        },
        employee: {
            jobtitle: "",
            custom_fields: {}
        },
        candidate: {
            is_dyslexic: false,
            is_dyscalc: false,
            btw_number: null,
            reference: "",
            preferred_certificate_language: "nl",
            email: "",
            custom_fields: {}
        },
        assessor: {
            type: [],
            custom_fields: {}
        },
        add_to_companies: [],
        addresses: [],
        emailaddresses: [],
    },
    register_candidate_model: {
        candidate: {
            notify: false,
            is_dyslexic: false,
            is_dyscalc: false,
            btw_number: null,
            reference: "",
            preferred_certificate_language: "nl",
            custom_fields: {},
            person: {
                type: "candidate",
                initials: null,
                first_name: null,
                insertion: null,
                last_name: null,
                date_of_birth: null,
                phonenumber: null,
                mobilenumber: null,
                billingnumber: null,
                gender: null,
                place_of_birth: null,
                addresses: [],
                access_to_portal: true,
                country_of_birth_id: null,
                nationality_id: null,
                financial_name: null
            },
        },
        company: {
            name: null,
            reference: null,
            phonenumber: null,
            billingnumber: null,
            addresses: [],
        },
        contact: {
            notify: false,
            person: {
                type: "contact",
                initials: null,
                first_name: null,
                insertion: null,
                last_name: null,
                date_of_birth: null,
                phonenumber: null,
                billingnumber: null,
                gender: null,
                country_of_birth_id: null,
                place_of_birth: null,
                nationality_id: null,
                addresses: [],
            },
            no_contact: false,
            newsletter: false,
        },
        exams: [],
    },
    exam_table_data: ["id", "name", "reference", "examination_date", "location_name", "room_name"],
    certificate_table_data: ["certificate_number", "name", "status", "issueing_date", "valid_until"],
    dossier_table_data: ["id", "name", "status", "is_closed"],
    company_table_data: ["id", "name", "reference", "phonenumber", "website"],
    contact_table_data: ["id", "name", "phonenumber", "mobilenumber", "can_view_certificates", "newsletter"],
    candidate_table_data: ["id", "name", "reference", "phonenumber", "mobilenumber"],
    room_table_data: ["id", "name", "location_name", "minimum_capacity", "maximum_capacity"],
    scheme_table_data: ["id", "name", "reference", "scheme_owner", "end_result"],
    person_fields: ['date_of_birth', 'phonenumber', 'mobilenumber', 'billingnumber', 'gender', 'place_of_birth', 'country_of_birth_id', 'nationality_id', 'financial_name'],
    true_checkbox: "<svg data-layer='53fdbc09-5421-4390-a26f-dd60a2515111' preserveAspectRatio='none' viewBox='2.999999523162842 2.999999523162842 25.999998092651367 26' class='icon-ready' width='18' height='18'><path fill='#008000' d='M 26.11111068725586 2.999999523162842 L 5.888888835906982 2.999999523162842 C 4.285554885864258 2.999999523162842 2.999999523162842 4.299999713897705 2.999999523162842 5.888888835906982 L 2.999999523162842 26.11111068725586 C 2.999999523162842 27.69999885559082 4.285554885864258 28.99999809265137 5.888888835906982 28.99999809265137 L 26.11111068725586 28.99999809265137 C 27.71444320678711 28.99999809265137 28.99999809265137 27.69999885559082 28.99999809265137 26.11111068725586 L 28.99999809265137 5.888888835906982 C 28.99999809265137 4.299999713897705 27.71444320678711 2.999999523162842 26.11111068725586 2.999999523162842 Z M 13.11111164093018 23.22222137451172 L 5.888888835906982 15.99999904632568 L 7.925554752349854 13.96333312988281 L 13.11111164093018 19.13444328308105 L 24.07444381713867 8.171111106872559 L 26.11111068725586 10.22222232818604 L 13.11111164093018 23.22222137451172 Z'></path></svg>",
    false_checkbox: "<svg data-layer='6e603f22-b4e4-4b9f-a319-a90a916d27dc' width='18' height='18' preserveAspectRatio='none' viewBox='2.999999523162842 2.999999523162842 25.999998092651367 26' class='icon-non'><path fill='#cc0000' d='M 26.11111068725586 2.999999523162842 L 5.888888835906982 2.999999523162842 C 4.299999713897705 2.999999523162842 2.999999523162842 4.299999713897705 2.999999523162842 5.888888835906982 L 2.999999523162842 26.11111068725586 C 2.999999523162842 27.69999885559082 4.299999713897705 28.99999809265137 5.888888835906982 28.99999809265137 L 26.11111068725586 28.99999809265137 C 27.69999885559082 28.99999809265137 28.99999809265137 27.69999885559082 28.99999809265137 26.11111068725586 L 28.99999809265137 5.888888835906982 C 28.99999809265137 4.299999713897705 27.69999885559082 2.999999523162842 26.11111068725586 2.999999523162842 Z M 23.22222137451172 17.44444274902344 L 8.777777671813965 17.44444274902344 L 8.777777671813965 14.55555534362793 L 23.22222137451172 14.55555534362793 L 23.22222137451172 17.44444274902344 Z'></path></svg>",
    //BASE TABLE AND BASE LINK FUNCTIONS
    sortByTagsBase(sorted_array, tags) {
        var response = [];
        for (let i = 0; i < sorted_array.length; i++) {
            if (sorted_array[i].tags.length > 0) {
                var found = false;
                for (let x = 0; x < sorted_array[i].tags.length; x++) {
                    for (let y = 0; y < tags.length; y++) {
                        if (sorted_array[i].tags[x] == tags[y]) {
                            response.push(sorted_array[i]);
                            found = true;
                            break;
                        }
                    }
                    if (found) {
                        break;
                    }
                }
            }
        }
        return response;
    },
    sortByColumn(sorted_array, sortBy, sortDesc) {
        let sort_field = sortBy;
        if (sortDesc) {
            sorted_array.sort((a, b) => {
                if (a[sort_field] == null) {
                    return 1;
                } else if (b[sort_field] == null) {
                    return -1;
                } else {
                    if(sort_field === 'search_score') {
                        return parseInt(a[sort_field].replace("%")) < parseInt(b[sort_field].replace("%")) ? 1 : parseInt(b[sort_field].replace("%")) < parseInt(a[sort_field].replace("%")) ? -1 : 0;
                    }
                    else {
                        return a[sort_field] < b[sort_field] ? 1 : b[sort_field] < a[sort_field] ? -1 : 0;
                    }
                }
            });
        } else {
            sorted_array.sort((a, b) => {
                if (a[sort_field] == null) {
                    return -1;
                } else if (b[sort_field] == null) {
                    return 1;
                } else {
                    if(sort_field === 'search_score') {
                        return parseInt(a[sort_field].replace("%")) > parseInt(b[sort_field].replace("%")) ? 1 : parseInt(b[sort_field].replace("%")) > parseInt(a[sort_field].replace("%")) ? -1 : 0;
                    }
                    else {
                        return a[sort_field] > b[sort_field] ? 1 : b[sort_field] > a[sort_field] ? -1 : 0;
                    }
                }
            });
        }
        return sorted_array;
    },
    searchInSortedArray(sorted_array, response_fields, search, search_column) {
        if(search_column && search_column !== 'all') {
            this.fuse_options.keys = [search_column];
        }
        else {
            this.fuse_options.keys = Object.keys(response_fields);
        }
        if (this.fuse_options.keys.includes("updated_at")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("updated_at"), 1);
        }
        if (this.fuse_options.keys.includes("created_at")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("created_at"), 1);
        }
        if (this.fuse_options.keys.includes("tags")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("tags"), 1);
        }
        if (this.fuse_options.keys.includes("search_score")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("search_score"), 1);
        }
        //PEOPLE
        if (this.fuse_options.keys.includes("date_of_birth")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("date_of_birth"), 1);
            this.fuse_options.keys.push("date_of_birth_search");
        }
        //EXAM FIX
        if (this.fuse_options.keys.includes("examination_date")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("examination_date"), 1);
            this.fuse_options.keys.push("examination_date_search");
        }
        //COURSE FIX
        if (this.fuse_options.keys.includes("start_date")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("start_date"), 1);
            this.fuse_options.keys.push("start_date_search");
        }
        if (this.fuse_options.keys.includes("end_date")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("end_date"), 1);
            this.fuse_options.keys.push("end_date_search");
        }
        //CERTIFICATE FIX
        if (this.fuse_options.keys.includes("valid_until")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("valid_until"), 1);
            this.fuse_options.keys.push("valid_until_search");
        }
        if (this.fuse_options.keys.includes("issueing_date")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("issueing_date"), 1);
            this.fuse_options.keys.push("issueing_date_search");
        }
        //DOSSIER FIX
        if (this.fuse_options.keys.includes("agreed_with_terms_on")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("agreed_with_terms_on"), 1);
            this.fuse_options.keys.push("agreed_with_terms_on_search");
        }
        //NOTE FIX
        if (this.fuse_options.keys.includes("expiration_date")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("expiration_date"), 1);
            this.fuse_options.keys.push("expiration_date_search");
        }
        const fuse = new Fuse(sorted_array, this.fuse_options);
        let result = fuse.search(search);
        var response = [];
        for (let x = 0; x < result.length; x++) {
            result[x].item.search_score =
                100 - Math.floor(result[x].score * 100) + "%";
            response.push(result[x].item);
        }
        return response;
    },
    //UNIVERSAL FUNCTIONS
    getLocalStorageValue(value_name) {
        if (localStorage.getItem(value_name)) {
            return JSON.parse(localStorage.getItem(value_name));
        }
        return null;
    },
    setLocalStorageValue(value_name, value) {
        localStorage.setItem(value_name, JSON.stringify(value));
    },
    getBreadcrumbs(module_name, current = null) {
        var response = [{
            text: "Dashboard",
            disabled: false
        }];
        if(module_name !== "dashboard") {
            response[0].to = "/";
            response[1] = {
                text: store.getters.translate(module_name)
            }
            if(current == null) {
                response[1].disabled = true;
            }
            else {
                response[1].disabled = false;
                response[1].href = "/" + module_name;
                response[2] = {
                    text: current,
                    disabled: true
                }
            }
        }
        return response;
    },
    //PORTAL FUNCTIONS
    getMenuItems(type, assessor_type, show_form) {
        var menu_items = [
            {
                title: store.getters.translate("home"),
                icon: "mdi-home",
                tab: 0,
            },
            {
                title: store.getters.translate("your_contactinformation"),
                icon: "mdi-account",
                tab: 1,
            },
        ];
        if(type === "assessor") {
            menu_items.push({
                title: store.getters.translate("exams"),
                icon: icons["exams"],
                tab: 2,
            });
            menu_items.push({
                title: store.getters.translate("documents"),
                icon: icons["notes"],
                tab: 3,
            });
            /*menu_items.push({
                title: store.getters.translate("planner"),
                icon: icons["planner"],
                tab: 2,
            });
            if(assessor_type !== "docent") {
                menu_items.push({
                    title: store.getters.translate("exams"),
                    icon: icons["exams"],
                    tab: 3,
                });
                menu_items.push({
                    title: store.getters.translate("documents"),
                    icon: icons["notes"],
                    tab: 4,
                });
            }*/
        }
        else if (type === "contact") {
            menu_items.push({
                title: store.getters.translate("exams"),
                icon: icons["exams"],
                tab: 2,
            });
            menu_items.push({
                title: store.getters.translate("candidates"),
                icon: icons["candidates"],
                tab: 3,
            });
        }
        else if (type === "candidate") {
            menu_items.push({
                title: store.getters.translate("exams"),
                icon: icons["exams"],
                tab: 2,
            });
            menu_items.push({
                title: store.getters.translate("documents"),
                icon: icons["notes"],
                tab: 3,
            });
            menu_items.push({
                title: store.getters.translate("certificates"),
                icon: icons["certificates"],
                tab: 4,
            });
            if(show_form) {
                menu_items.push({
                    title: store.getters.translate("personal_evaluation_form_exam"),
                    icon: icons["forms"],
                    tab: 5,
                });
            }
            /*menu_items.push({
                title: store.getters.translate("books"),
                icon: icons["books"],
                tab: 5,
            });
            if(show_form) {
                menu_items.push({
                    title: store.getters.translate("personal_evaluation_form_exam"),
                    icon: icons["forms"],
                    tab: 6,
                });
            }*/
        }
        return menu_items;
    },
    getCertificateHeaders(visible_contact) {
        var certificate_headers = [
            {
                text: store.getters.translate("certificate_number"),
                align: "start",
                sortable: true,
                value: "certificate_number",
            },
            {
                text: store.getters.translate("name"),
                align: "start",
                sortable: true,
                value: "name",
            },
            { text: store.getters.translate("status"), value: "status" },
            {
                text: store.getters.translate("download"),
                align: "right",
                value: "download",
            },
        ];
        if(visible_contact) {
            certificate_headers.push({
                text: store.getters.translate("visible_contact"),
                align: "start",
                value: "visible_contact",
            });
        }
        return certificate_headers;
    },
    getSessionHeaders(user_type, show_from_till) {
        if (user_type === "candidate") {
            if (show_from_till) {
                return [
                    { text: store.getters.translate("starting_time"), value: "from" },
                    { text: store.getters.translate("till"), value: "till" },
                    { text: store.getters.translate("minimal_score"), value: "cesuur" },
                    { text: store.getters.translate("result"), value: "result" },
                    { text: store.getters.translate("status"), value: "status" },
                ];
            } else {
                return [
                    { text: store.getters.translate("minimal_score"), value: "cesuur" },
                    { text: store.getters.translate("result"), value: "result" },
                    { text: store.getters.translate("status"), value: "status" },
                ];
            }
        }
        else if (show_from_till) {
            return [
                { text: store.getters.translate("candidate"), value: "name" },
                { text: store.getters.translate("starting_time"), value: "from" },
                { text: store.getters.translate("till"), value: "till" },
                { text: store.getters.translate("minimal_score"), value: "cesuur" },
                { text: store.getters.translate("result"), value: "result" },
                { text: store.getters.translate("status"), value: "status" },
                { text: store.getters.translate("actions"), value: "actions", align: "right" },
            ];
        } else {
            return [
                { text: store.getters.translate("candidate"), value: "name" },
                { text: store.getters.translate("minimal_score"), value: "cesuur" },
                { text: store.getters.translate("result"), value: "result" },
                { text: store.getters.translate("status"), value: "status" },
                { text: store.getters.translate("actions"), value: "actions", align: "right" },
            ];
        }
    },
    //CONVERT MODEL_ID TO MODEL_NAME FUNCTIONS
    getLocationsAndRooms(records, location = false, room = false) {
        for (let i = 0; i < records.length; i++) {
            if(location) {
                if (records[i].location) {
                    records[i].location_name = records[i].location.name;
                } else {
                    records[i].location_name = "";
                }
            }
            if(room) {
                if (records[i].room) {
                    records[i].room_name = records[i].room.name;
                } else {
                    records[i].room_name = "";
                }
            }
        }
        return records;
    },
    //EXAM AND COURSE FUNCTIONS
    getLocations(scheme, type) {
        let locations = [];
        let added_locations = [];
        scheme.assessors.forEach((assessor) => {
            if((type === 'course' && assessor.type.includes("docent")) ||
                (type === 'exam' && (assessor.type.includes("examinator") || assessor.type.includes("supervisor")))) {
                assessor.rooms.forEach((room) => {
                    if (!added_locations.includes(room.location.id)) {
                        added_locations.push(room.location.id);
                        locations.push(room.location);
                    }
                });
            }
        });
        return locations;
    },
    getRooms(scheme, location_id, type) {
        let rooms = [];
        var added_rooms = [];
        scheme.assessors.forEach((assessor) => {
            if((type === 'course' && assessor.type.includes("docent")) ||
                (type === 'exam' && (assessor.type.includes("examinator") || assessor.type.includes("supervisor")))) {
                assessor.rooms.forEach((room) => {
                    if (room.location_id === location_id && !added_rooms.includes(room.id)) {
                        added_rooms.push(room.id);
                        rooms.push(room);
                    }
                });
            }
        });
        return rooms;
    },
    getAssessors(scheme, room_id, type) {
        let assessors = [];
        let added_assessors = [];
        scheme.assessors.forEach((assessor) => {
            if (assessor.type.includes(type)) {
                assessor.rooms.forEach((room) => {
                    if (room.id === room_id && !added_assessors.includes(assessor.id)) {
                        added_assessors.push(assessor.id);
                        assessors.push(assessor);
                    }
                });
            }
        });
        return assessors;
    },
    //PLANNER FUNCTIONS
    getColor(title) {
        if (title === "Praktijk") {
            return "#CCFFFF";
        }
        else if (title === "Certificatie") {
            return "#FFCC99";
        }
        else if (title === "Schakelbericht") {
            return "#ff99cc";
        }
        else if (title === "Basis") {
            return "#FFFF99";
        }
        else if (title === "Theorie - meerkeuzevragen" || title === "Theorie - openvragen") {
            return "#d8e4bc";
        }
        else {
            return "deepskyblue";
        }
    },
    getHexRgbColor(color, export_type) {
        if(export_type === 'pdf') {
            return color;
        }
        else if (color === "#CCFFFF") {
            return "RGB(204, 255, 255)";
        }
        else if (color === "#FFCC99") {
            return "RGB(255, 204, 153)";
        }
        else if (color === "#ff99cc") {
            return "RGB(255, 153, 204)";
        }
        else if (color === "#FFFF99") {
            return "RGB(255, 255, 153)";
        }
        else if (color === "#d8e4bc") {
            return "RGB(216, 228, 188)";
        }
        else if (color === "black") {
            return "RGB(0, 0, 0)";
        }
        else if (color === "white") {
            return "RGB(255, 255, 255)";
        }
        else if (color === "grey") {
            return "RGB(128, 128, 128)";
        }
        else if (color === "#56d65b") {
            return "RGB(86, 214, 91)";
        }
        else if (color === "#ff7f75") {
            return "RGB(255, 127, 117)";
        }
        else {
            return "RGB(0, 191, 255)";
        }
    },
    backgroundColor(cesuur, item) {
        if (item.status === "exempt" || item.status === "absent") {
            return "grey";
        }
        if (parseInt(cesuur) > parseInt(item.result) || !item.result) {
            return "#ff7f75";
        }
        return "#56d65b";
    },
    getTitle(session_name) {
        if (session_name === "Praktijk") {
            return "Praktijk";
        }
        else if (session_name === "Certficatietoets" || session_name === "Certificatie toets" || session_name === "Certificatietoets" || session_name === "certificatietoets") {
            return "Certificatie";
        }
        else if (session_name === "Scakelbericht" || session_name === "Schakelbericht") {
            return "Schakelbericht";
        }
        else if (session_name === "Basistoets" || session_name === "Basis toets") {
            return "Basis";
        }
        else if (session_name === "Theorie - meerkeuzevragen" || session_name === "Theorie - Meerkeuzevragen" || session_name === "Theory multiple choice" ||
            session_name === "Meerkeuze vragen" || session_name === "Theorie meerkeuze" || session_name === "Theorietoets meerkeuze" || session_name === "Meerkeuze") {
            return "Theorie - meerkeuzevragen";
        }
        else if (session_name === "Theorie - openvragen" || session_name === "Openvragen" || session_name === "Theorie open" || session_name === "Theorie open vragen" || session_name === "Theorie openvragen") {
            return "Theorie - openvragen";
        }
        else {
            return session_name;
        }
    },
    getDuration(scheme_session) {
        var duration = "01:00";
        if(scheme_session.duration) {
            duration = scheme_session.duration;
        }
        return duration;
    },
    exportCalendar(export_type, resources) {
        let html = this.generateTableHeader(export_type) + '<tr><th></th>';
        let resource_events = [];
        resources.forEach(resource => {
            //DOING 2 COLUMNS FOR EACH CANDIDATE IN CASE IF HE`S SESSIONS INTERSECT
            html += '<th colspan="2">' + resource._resource.title + '</th>';
            let events = [];
            resource.getEvents().forEach(event => {
                let duration = moment.duration(moment(event._instance.range.end).diff(moment(event._instance.range.start)));
                //ADJUST MINUTES TO % 5
                let start_minutes = Math.floor(moment.utc(event._instance.range.start).minutes() / 5) * 5;
                let end_minutes = Math.floor(moment.utc(event._instance.range.end).minutes() / 5) * 5;
                events.push({
                    title: event._def.title,
                    backgroundColor: event.backgroundColor ? event.backgroundColor : 'white',
                    textColor: event.textColor ? event.textColor : 'black',
                    start: moment.utc(event._instance.range.start).minute(start_minutes).format("HH:mm"),
                    end: moment.utc(event._instance.range.end).minute(end_minutes).format("HH:mm"),
                    duration: Math.floor(((duration.hours() * 60) + duration.minutes()) / 5), //SAVE IN STEPS % 5 MINUTES
                });
            });
            resource_events.push(events);
        });
        html += '</tr>';
        let times = this.generateTimes();
        for (let i = 0; i < times.length; i++) {
            html += '<tr>';
            //FIRST COLUMN WITH TIME
            if(i === 0 || i % 3 === 0) {
                html += '<td>' + times[i] + '</td>';
            }
            else {
                html += '<td></td>';
            }
            //RESOURCE COLUMNS
            resource_events.forEach(events => {
                //CHECK IF CANDIDATE`S SESSIONS INTERSECT
                let duplicated_event_exists = false;
                for (let x = 0; x < events.length; x++) {
                    for (let y = 0; y < events.length; y++) {
                        if(x !== y && moment(events[x].start, "HH:mm").isBetween(moment(events[y].start, "HH:mm"), moment(events[y].end, "HH:mm"))) {
                            duplicated_event_exists = true;
                            break;
                        }
                    }
                    if(duplicated_event_exists) {
                        break;
                    }
                }
                let event_exists_counter = 0;
                for (let x = 0; x < events.length; x++) {
                    if(events[x].start === times[i]) {
                        if(duplicated_event_exists) {
                            html += '<td style="color: ' + this.getHexRgbColor(events[x].textColor, export_type) + '; ' +
                                'background-color: ' + this.getHexRgbColor(events[x].backgroundColor, export_type) + ';" ' +
                                'rowspan="' + events[x].duration + '">' + events[x].start + ' - ' + events[x].end + '<br/>' + events[x].title + '</td>';
                        }
                        else {
                            //TAKE BOTH COLUMNS BECAUSE CANDIDATE`S SESSIONS NOT INTERSECT
                            html += '<td colspan="2" style="color: ' + this.getHexRgbColor(events[x].textColor, export_type) + '; ' +
                                'background-color: ' + this.getHexRgbColor(events[x].backgroundColor, export_type) + ';" ' +
                                'rowspan="' + events[x].duration + '">' + events[x].start + ' - ' + events[x].end + '<br/>' + events[x].title + '</td>';
                        }
                        event_exists_counter++;
                    }
                    else if(moment(times[i], "HH:mm").isBetween(moment(events[x].start, "HH:mm"), moment(events[x].end, "HH:mm"))) {
                        event_exists_counter++;
                    }
                }
                if(event_exists_counter === 0) {
                    html += '<td colspan="2"></td>';
                }
                else if(duplicated_event_exists && event_exists_counter === 1) {
                    html += '<td></td>';
                }
            });
            html += '</tr>';
        }
        html += '</table>';

        return html;
    },
    exportDossier(export_type, record, record_results) {
        let html = this.generateTableHeader(export_type); //OPEN MAIN TABLE TAG
        html += this.generateTableSubheader(export_type, record.name, 2);
        html += this.generateSimpleRow('status', store.getters.translate(record.status));
        html += this.generateSimpleRow('scheme', record.scheme.name);
        html += this.generateSimpleRow('root_exam', record.root_exam.name);
        html += this.generateSimpleRow('candidate', record.candidate.name);
        html += this.generateSimpleRow('agreed_with_terms_on', record.agreed_with_terms_on);
        html += this.generateSimpleRow('tags', record.tags.join(", "));
        html += this.generateBooleanRow(export_type, 'is_closed', record.is_closed);
        //EXAMS
        if(record.exams && record.exams.length > 0) {
            html += '<tr><td colspan="2" style="padding: 0;"/></tr>';
            html += '<tr><td colspan="2" style="padding: 0;">'; //OPEN TR/TD IN MAIN TABLE
            html += this.generateTableHeader(export_type); //OPEN EXAM TABLE TAG
            html += this.generateTableSubheader(export_type, store.getters.translate('exams'), this.exam_table_data.length);
            record_results.forEach((record_result, key) => {
                if (!record.exams[key].re_examination || record.exams.length === 1) {
                    //EXAMS HEADER
                    html += this.generateAdvancedHeaders(this.exam_table_data);
                    //EXAMS DATA
                    html += this.generateAdvancedRow(record.exams[key], this.exam_table_data);
                    html += this.generateResultsRow(export_type, record_result);
                    record_results.forEach((record_result_2, key2) => {
                        if (record.exams[key2].re_examination && record.exams[key2].re_exam_id === record.exams[key].id) {
                            html += this.generateAdvancedRow(record.exams[key2], this.exam_table_data);
                            html += this.generateResultsRow(export_type, record_result_2);
                            record_results.forEach((record_result_3, key3) => {
                                if (record.exams[key3].re_examination && record.exams[key3].re_exam_id === record.exams[key2].id) {
                                    html += this.generateAdvancedRow(record.exams[key3], this.exam_table_data);
                                    html += this.generateResultsRow(export_type, record_result_3);
                                    record_results.forEach((record_result_4, key4) => {
                                        if (record.exams[key4].re_examination && record.exams[key4].re_exam_id === record.exams[key3].id) {
                                            html += this.generateAdvancedRow(record.exams[key4], this.exam_table_data);
                                            html += this.generateResultsRow(export_type, record_result_4);
                                            record_results.forEach((record_result_5, key5) => {
                                                if (record.exams[key5].re_examination && record.exams[key5].re_exam_id === record.exams[key4].id) {
                                                    html += this.generateAdvancedRow(record.exams[key5], this.exam_table_data);
                                                    html += this.generateResultsRow(export_type, record_result_5);
                                                    record_results.forEach((record_result_6, key6) => {
                                                        if (record.exams[key6].re_examination && record.exams[key6].re_exam_id === record.exams[key5].id) {
                                                            html += this.generateAdvancedRow(record.exams[key6], this.exam_table_data);
                                                            html += this.generateResultsRow(export_type, record_result_6);
                                                            record_results.forEach((record_result_7, key7) => {
                                                                if (record.exams[key7].re_examination && record.exams[key7].re_exam_id === record.exams[key6].id) {
                                                                    html += this.generateAdvancedRow(record.exams[key7], this.exam_table_data);
                                                                    html += this.generateResultsRow(export_type, record_result_7);
                                                                }
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
            html += '</table>'; //CLOSE EXAM TABLE TAG
            html += '</td></tr>'; //CLOSE TR/TD IN MAIN TABLE
        }
        html += this.generateIncludableTable(export_type, 'certificates', record.certificates, this.certificate_table_data);
        html += '</table>'; //CLOSE MAIN TABLE TAG

        return html;
    },
    exportCompany(export_type, record, usual_fields, custom_fields) {
        let html = this.generateTableHeader(export_type);
        html += this.generateTableSubheader(export_type, record.name, 2);
        usual_fields.forEach(field => {
            html += this.generateSimpleRow(field.name, record[field.name]);
        });
        custom_fields.forEach(field => {
            if(field.field_type === 'boolean') {
                html += this.generateBooleanRow(export_type, field.name, record.custom_fields[field.name]);
            }
            else if(field.field_type === 'select_multiple') {
                html += this.generateSimpleRow(field.name, record.custom_fields[field.name].join(", "));
            }
            else {
                html += this.generateSimpleRow(field.name, record.custom_fields[field.name]);
            }
        });
        record.emailaddresses.forEach(email => {
            html += this.generateSimpleRow('email', email.emailaddress);
        });
        record.addresses.forEach(address => {
            html += this.generateSimpleRow('address', address.name);
        });
        html += this.generateSimpleRow('tags', record.tags.join(", "));
        html += this.generateIncludableTable(export_type, 'contacts', record.contacts, this.contact_table_data);
        html += this.generateIncludableTable(export_type, 'candidates', record.candidates, this.candidate_table_data);
        html += '</table>';

        return html;
    },
    exportPerson(export_type, user_type, record, usual_fields, custom_fields) {
        let html = this.generateTableHeader(export_type);
        html += this.generateTableSubheader(export_type, record.name, 2);
        usual_fields.forEach(field => {
            if(field.name === 'jobtitle' || field.name === 'reference' || field.name === 'btw_number') {
                html += this.generateSimpleRow(field.name, record[field.name]);
            }
            else if(field.name === 'preferred_certificate_language') {
                html += this.generateSimpleRow(field.name, store.getters.translate(record[field.name]));
            }
            else if(field.name === 'can_view_certificates' || field.name === 'newsletter' || field.name === 'is_dyslexic' || field.name === 'is_dyscalc') {
                html += this.generateBooleanRow(export_type, field.name, record[field.name]);
            }
            else if(field.name === 'type') {
                html += this.generateSimpleRow(field.name, record[field.name].join(", "));
            }
            else if(field.name === 'country_of_birth_id' || field.name === 'nationality_id') {
                html += this.generateSimpleRow(field.name, record.person[field.name.replace('_id', '')]);
            }
            else if(field.name === 'gender') {
                html += this.generateSimpleRow(field.name, store.getters.translate(record.person[field.name]));
            }
            else {
                html += this.generateSimpleRow(field.name, record.person[field.name]);
            }
        });
        custom_fields.forEach(field => {
            if(record.custom_fields[field.name]) {
                if (field.field_type === 'boolean') {
                    html += this.generateBooleanRow(export_type, field.name, record.custom_fields[field.name]);
                } else if (field.field_type === 'select_multiple') {
                    html += this.generateSimpleRow(field.name, record.custom_fields[field.name].join(", "));
                } else {
                    html += this.generateSimpleRow(field.name, record.custom_fields[field.name]);
                }
            }
        });
        if(record.person.user) {
            html += this.generateSimpleRow('user_email', record.person.user.email);
        }
        record.person.emailaddresses.forEach(email => {
            html += this.generateSimpleRow('email', email.emailaddress);
        });
        record.person.addresses.forEach(address => {
            html += this.generateSimpleRow('address', address.name);
        });
        html += this.generateSimpleRow('tags', record.tags.join(", "));
        if(user_type === 'candidate') {
            html += this.generateIncludableTable(export_type, 'certificates', record.certificates, this.certificate_table_data);
            //EXAMS
            if(record.exams_for_export && record.exams_for_export.length > 0) {
                html += '<tr><td colspan="2" style="padding: 0;"/></tr>';
                html += '<tr><td colspan="2" style="padding: 0;">'; //OPEN TR/TD IN MAIN TABLE
                html += this.generateTableHeader(export_type); //OPEN EXAM TABLE TAG
                html += this.generateTableSubheader(export_type, store.getters.translate('exams'), this.exam_table_data.length);
                record.record_results.forEach((record_result, key) => {
                    if (!record.exams_for_export[key].re_examination || record.exams_for_export.length === 1) {
                        //EXAMS HEADER
                        html += this.generateAdvancedHeaders(this.exam_table_data);
                        //EXAMS DATA
                        html += this.generateAdvancedRow(record.exams_for_export[key], this.exam_table_data);
                        html += this.generateResultsRow(export_type, record_result);
                        record.record_results.forEach((record_result_2, key2) => {
                            if (record.exams_for_export[key2].re_examination && record.exams_for_export[key2].re_exam_id === record.exams_for_export[key].id) {
                                html += this.generateAdvancedRow(record.exams_for_export[key2], this.exam_table_data);
                                html += this.generateResultsRow(export_type, record_result_2);
                                record.record_results.forEach((record_result_3, key3) => {
                                    if (record.exams_for_export[key3].re_examination && record.exams_for_export[key3].re_exam_id === record.exams_for_export[key2].id) {
                                        html += this.generateAdvancedRow(record.exams_for_export[key3], this.exam_table_data);
                                        html += this.generateResultsRow(export_type, record_result_3);
                                        record.record_results.forEach((record_result_4, key4) => {
                                            if (record.exams_for_export[key4].re_examination && record.exams_for_export[key4].re_exam_id === record.exams_for_export[key3].id) {
                                                html += this.generateAdvancedRow(record.exams_for_export[key4], this.exam_table_data);
                                                html += this.generateResultsRow(export_type, record_result_4);
                                                record.record_results.forEach((record_result_5, key5) => {
                                                    if (record.exams_for_export[key5].re_examination && record.exams_for_export[key5].re_exam_id === record.exams_for_export[key4].id) {
                                                        html += this.generateAdvancedRow(record.exams_for_export[key5], this.exam_table_data);
                                                        html += this.generateResultsRow(export_type, record_result_5);
                                                        record.record_results.forEach((record_result_6, key6) => {
                                                            if (record.exams_for_export[key6].re_examination && record.exams_for_export[key6].re_exam_id === record.exams_for_export[key5].id) {
                                                                html += this.generateAdvancedRow(record.exams_for_export[key6], this.exam_table_data);
                                                                html += this.generateResultsRow(export_type, record_result_6);
                                                                record.record_results.forEach((record_result_7, key7) => {
                                                                    if (record.exams_for_export[key7].re_examination && record.exams_for_export[key7].re_exam_id === record.exams_for_export[key6].id) {
                                                                        html += this.generateAdvancedRow(record.exams_for_export[key7], this.exam_table_data);
                                                                        html += this.generateResultsRow(export_type, record_result_7);
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
                html += '</table>'; //CLOSE EXAM TABLE TAG
                html += '</td></tr>'; //CLOSE TR/TD IN MAIN TABLE
            }
            html += this.generateIncludableTable(export_type, 'dossiers', record.dossiers, this.dossier_table_data);
            html += this.generateIncludableTable(export_type, 'companies', record.companies, this.company_table_data);
            html += this.generateIncludableTable(export_type, 'contacts', record.contacts, this.contact_table_data);
        }
        else if(user_type === 'assessor') {
            html += this.generateIncludableTable(export_type, 'rooms', record.rooms, this.room_table_data);
            html += this.generateIncludableTable(export_type, 'schemes', record.schemes, this.scheme_table_data);
        }
        else if(user_type === 'contact') {
            html += this.generateIncludableTable(export_type, 'companies', record.companies, this.company_table_data);
            html += this.generateIncludableTable(export_type, 'candidates', record.candidates, this.candidate_table_data);
        }
        html += '</table>';

        return html;
    },
    generateIncludableTable(export_type, text, data, headers) {
        let html = '';
        if(data && data.length > 0) {
            html += '<tr><td colspan="2" style="padding: 0;"/></tr>';
            html += '<tr><td colspan="2" style="padding: 0;">'; //OPEN TR/TD IN MAIN TABLE
            html += this.generateTableHeader(export_type); //OPEN TABLE TAG
            html += this.generateTableSubheader(export_type, store.getters.translate(text), headers.length);
            //HEADER
            html += this.generateAdvancedHeaders(headers);
            //DATA
            data.forEach((record) => {
                html += this.generateAdvancedRow(record, headers);
            });
            html += '</table>'; //CLOSE TABLE TAG
            html += '</td></tr>'; //CLOSE TR/TD IN MAIN TABLE
        }

        return html;
    },
    generateBooleanRow(export_type, key, value) {
        if(value) {
            if(export_type === 'excel') {
                return this.generateSimpleRow(key, store.getters.translate('true'));
            }
            else {
                return this.generateSimpleRow(key, this.true_checkbox);
            }
        }
        else {
            if(export_type === 'excel') {
                return this.generateSimpleRow(key, store.getters.translate('false'));
            }
            else {
                return this.generateSimpleRow(key, this.false_checkbox);
            }
        }
    },
    generateTableHeader(export_type) {
        if(export_type === 'excel') {
            return '<table border="2">';
        }
        else {
            return '<table style="border-spacing: 0px; -webkit-border-spacing: 0px; border-collapse: collapse; -webkit-border-collapse: collapse; width: 100%;">';
        }
    },
    generateTableSubheader(export_type, text, colspan = 2) {
        return '<tr><th colspan="' + colspan + '" style="text-align: center; color: ' + this.getHexRgbColor("white") + '; background-color: ' + this.getHexRgbColor("deepskyblue", export_type) + ';">' + text + '</th></tr>';
    },
    generateSimpleRow(key, value) {
        if (value == null) {
            value = '';
        }
        return '<tr><td>' + store.getters.translate(key) + ':</td><td>' + value + '</td></tr>';
    },
    generateAdvancedRow(data, headers) {
        let html = '<tr>';
        for (let i = 0; i < headers.length; i++) {
            if (data[headers[i]] == null) {
                data[headers[i]] = '';
            }
            if (data.person && data.person[headers[i]] == null) {
                data.person[headers[i]] = '';
            }
            if(headers[i] === 'status' || headers[i] === 'end_result') {
                html += '<td>' + store.getters.translate(data[headers[i]]) + '</td>';
            }
            else if(this.person_fields.includes(headers[i]) && data.person) {
                html += '<td>' + data.person[headers[i]] + '</td>';
            }
            else {
                html += '<td>' + data[headers[i]] + '</td>';
            }
        }
        html += '</tr>';
        return html;
    },
    generateAdvancedHeaders(headers) {
        let html = '<tr>';
        for (let i = 0; i < headers.length; i++) {
            html += '<th style="text-align: center;">' + store.getters.translate(headers[i]) + '</th>';
        }
        html += '</tr>';
        return html;
    },
    generateResultsRow(export_type, record_result) {
        let html = '<tr><td colspan="' + this.exam_table_data.length + '" style="padding: 0;">'; //OPEN TR/TD IN EXAMS TABLE
        html += this.generateTableHeader(export_type); //OPEN RESULTS TABLE TAG
        html += '<tr>'; //OPEN HEADER TR
        record_result.scheme.scheme_sessions.forEach((scheme_session) => {
            html += '<td style="text-align: center;">' + scheme_session.name + ' (' + scheme_session.cesuur + ')' + '</td>';
        });
        html += '</tr><tr>'; //CLOSE HEADER TR, OPEN BODY TR
        record_result.scheme.scheme_sessions.forEach((scheme_session) => {
            let result_found = false;
            record_result.results.forEach((exam_candidate_session) => {
                if(exam_candidate_session.scheme_session_id === scheme_session.id) {
                    result_found = true;
                    let background_color = this.backgroundColor(scheme_session.cesuur, exam_candidate_session);
                    html += '<td style="text-align: center; color: ' + this.getHexRgbColor("white") + '; background-color: ' + this.getHexRgbColor(background_color) + ';">' + exam_candidate_session.result + '</td>';
                }
            });
            if(!result_found) {
                html += '<td style="text-align: center; color: ' + this.getHexRgbColor("black") + '; background-color: ' + this.getHexRgbColor("white") + ';">-</td>';
            }
        });
        html += '</tr>'; //CLOSE BODY TR
        html += '</table>'; //CLOSE RESULTS TABLE TAG
        html += '</td></tr>'; //CLOSE TR/TD IN EXAMS TABLE
        html += '<tr><td colspan="' + this.exam_table_data.length + '" style="padding: 0;"/></tr>'; //ADD EMPTY LINE
        return html;
    },
    getPlannerEvent(id, resourceId, date, start_time, end_time, title, color, description = null) {
        return {
            "id": id,
            "resourceId": resourceId,
            "allDay": false,
            "start": moment(date + " " + start_time, "DD-MM-YYYY HH:mm").toDate(),
            "end": moment(date + " " + end_time, "DD-MM-YYYY HH:mm").toDate(),
            "title": title,
            "color": color,
            "description": description,
            "resourceEditable": false
        };
    },
    generateTimes() {
        let times = [];
        times[0] = "08:00";
        for (let i = 1; i < 120; i++) {
            times[i] = moment(times[i-1], "HH:mm").add({ minute: 5 }).format("HH:mm");
        }
        return times;
    },
    getEmptyPlannerTableRow(id, counter, duration, title, color) {
        return '<td class="pa-2 draggable-class" id="' + id + '_' + counter + '" ' +
            'style="border:1px solid grey; background-color: white; cursor: pointer; height: 40px;" ' +
            'data-event=\'{"id": "' + id + '", "resourceId": "' + counter + '", ' +
            '"duration": "' + duration + '", "title": "' + title + '", "color": "' + color + '", ' +
            '"resourceEditable": false}\'></td>';
    },
    getFilledPlannerTableRow(id, counter, duration, title, color) {
        return '<td class="pa-2 draggable-class" id="' + id + '_' + counter + '" ' +
            'style="border:1px solid grey; background-color: ' + color + '; cursor: pointer; height: 40px;" ' +
            'data-event=\'{"id": "' + id + '", "resourceId": "' + counter + '", ' +
            '"duration": "' + duration + '", "title": "' + title + '", "color": "' + color + '", ' +
            '"resourceEditable": false}\'>' + title + '</td>';
    }
}

export default helpFunctions