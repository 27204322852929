export default {
    dossiers: 'dossiers',
    certificates: 'certificates',
    exams: 'exams',
    candidates: 'candidates',
    courses: 'courses',
    exam_candidates: 'ExamCandidates',
    forms: 'forms',
    formquestions: 'questions',
    schemes: 'schemes',
    locations: 'locations',
    notes: 'notes',
    assessors: 'assessors',
    rooms: 'rooms',
    schemesessions: 'schemesessions',
    translations: 'translations',
    templates: 'templates',
    companies: 'companies',
    people: 'people',
    sessions: 'sessions',
}