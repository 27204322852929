export default {
    // modules
    dossiers: 'mdi-briefcase-account',
    certificates: 'mdi-certificate',
    exams: 'mdi-school',
    candidates: 'mdi-account-box',
    exam_candidates: 'mdi-account-box',
    schemes: 'mdi-newspaper',
    locations: 'mdi-warehouse',
    notes: 'mdi-note',
    assessors: 'mdi-teach',
    rooms: 'mdi-google-classroom',
    schemesessions: 'mdi-newspaper-plus',
    sessions: 'mdi-newspaper-plus',
    translations: 'mdi-translate',
    templates: 'mdi-file-document-edit',
    companies: 'mdi-office-building',
    people: 'mdi-human-male-female',
    dashboard: 'mdi-desktop-mac-dashboard',
    tags: 'mdi-tag',
    planner: 'mdi-calendar',
    examstatuses: "mdi-list-status",
    // actions
    created: 'mdi-plus',
    result_set: 'mdi-content-save-move-outline',
    fields: 'mdi-format-letter-matches',
    users: 'mdi-account',
    roles: 'mdi-account-group',
    workflows: 'mdi-transit-connection-variant',
    settings: 'mdi-cogs',
    profile: 'mdi-face',
    contacts: 'mdi-contacts-outline',
    tokens: 'mdi-account-key',
    employees: 'mdi-account',
    files: 'mdi-file-multiple',
    forms: 'mdi-file-table-outline',
    formquestions: 'mdi-help-circle-outline',
    questioncategories: 'mdi-format-list-bulleted',
    //AESTIMAVI ONLY
    courses: 'mdi-notebook',
    books: 'mdi-bookshelf',
}