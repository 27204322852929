export default {
    addresses: 'Address',
    assessors: 'Assessor',
    candidates: 'Candidate',
    certificates: 'Certificate',
    companies: 'Company',
    contacts: 'Contact',
    courses: 'Course',
    dossiers: 'Dossier',
    emailaddresses: 'Emailaddress',
    employees: 'Employee',
    exams: 'Exam',
    exam_candidates: 'ExamCandidate',
    fields: 'Field',
    forms: 'Form',
    formquestions: 'FormQuestion',
    locations: 'Location',
    notes: 'Note',
    people: 'Person',
    planner: 'Planner',
    questioncategories: 'QuestionCategory',
    roles: 'Role',
    rooms: 'Room',
    schemes: 'Scheme',
    sessions: 'Session',
    schemesessions: 'SchemeSession',
    templates: 'Template',
    tokens: 'Token',
    translations: 'Translation',
    users: 'User',
    workflows: 'Workflow',
    tags: 'Tag',
}